<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="sousuo">
      <div class="div1">
        <span>菜单名称</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>
        <el-select
          style="width: 170px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="90"></el-table-column>
        <el-table-column
          prop="orgName"
          label="组织机构名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="orgEname"
          label="机构英文名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="linkName"
          label="联系人名称"
          width="120"
        ></el-table-column>
        <el-table-column prop="sex" label="联系人性别" width="120">
          <template slot-scope="scope">{{
            scope.row.sex == "0" ? "女" : "男"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="credit_code" label="统一社会信用代码" width="180"></el-table-column> -->
        <el-table-column prop="delFlag" label="删除标识" width="120">
          <template slot-scope="scope">{{
            scope.row.delFlag ? "有效" : "删除"
          }}</template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建日期" width="140">
          <template slot-scope="scope">{{
            scope.row.createDate | dateFormatYYmmddHHMM
          }}</template>
        </el-table-column>
        <el-table-column prop="updateDate" label="修改日期" width="120">
          <template slot-scope="scope">{{
            scope.row.updateDate | dateFormatYYmmdd
          }}</template>
        </el-table-column>
        <!-- <el-table-column prop="remark" label="备注" width="120"></el-table-column> -->
        <el-table-column prop="caozuo" label="操作" width="230">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="changeOrg(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="deleteOrg(scope.$index, scope.row)"
              alt
            />
            <el-button
              class="details"
              size="mini"
              @click="details(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="this.pageSize"
            layout="total, prev, pager, next"
            :total="this.totalCount"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <div class="buttons">
          <el-button type="text" @click="add">添加机构</el-button>
        </div>
      </div>
    </div>

    <!-- 添加机构弹框-->
    <div class="accountadd">
      <el-dialog
        title="添加机构"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearorgForm"
      >
        <!-- <el-select
          v-model="value"
          placeholder="请选择"
          @change="getValue(value)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.id"
            clearable
          >
          </el-option>
        </el-select> -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <!-- <el-form-item label="组织机构id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm.id" style="width:230px"></el-input>
          </el-form-item> -->
          <el-form-item label="组织机构名称" prop="name">
            <el-input
              v-model="ruleForm.orgName"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人名称" prop="name">
            <el-input
              v-model="ruleForm.linkName"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人性别" prop="name">
            <el-select
              v-model="ruleForm.sex"
              placeholder="请选择"
              @change="getSexValue"
            >
              <el-option
                v-for="item in options2"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="name">
            <el-input v-model="ruleForm.phone" style="width: 230px"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="name">
            <el-input
              v-model="ruleForm.creditCode"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构英文名称" prop="orgEname">
            <el-input
              v-model="ruleForm.orgEname"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构logo图标" prop="logoUrl">
            <el-button type="primary" plain @click="pushImage"
              >上传图片</el-button
            >
            <img :src="this.imageUrl" style="width: 200px" id="addProImage" />
            <!-- <el-input v-model="ruleForm.logoUrl" style="width:230px"></el-input> -->
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearorgForm">取 消</el-button>
          <el-button type="primary" @click="addOrgList">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 修改机构弹框-->
    <div class="accountadd">
      <el-dialog
        title="编辑机构"
        :visible.sync="dialogVisible2"
        width="50%"
        :before-close="clearorgForm2"
      >
        <!-- <el-select
          v-model="value"
          placeholder="请选择"
          @change="getValue(value)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.id"
            clearable
          >
          </el-option>
        </el-select> -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <!-- <el-form-item label="组织机构id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm.id" style="width:230px"></el-input>
          </el-form-item> -->
          <el-form-item label="组织机构名称" prop="name">
            <el-input
              v-model="ruleForm.orgName"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人名称" prop="name">
            <el-input
              v-model="ruleForm.linkName"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人性别" prop="name">
            <el-select
              v-model="ruleForm.sex"
              placeholder="请选择"
              @change="getSexValue"
            >
              <el-option
                v-for="item in options2"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="name">
            <el-input v-model="ruleForm.phone" style="width: 230px"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="name">
            <el-input
              v-model="ruleForm.creditCode"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构英文名称" prop="orgEname">
            <el-input
              v-model="ruleForm.orgEname"
              style="width: 230px"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构logo图标" prop="logoUrl">
            <el-button type="primary" plain @click="pushImage1"
              >上传图片</el-button
            >
            <img :src="this.imageUrl" style="width: 200px" id="gengaiImage" />
            <!-- <el-input v-model="ruleForm.logoUrl" style="width:230px"></el-input> -->
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="changeOrgList">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 点击详情的弹框 -->
    <div class="account">
      <el-dialog
        title="机构详情"
        :visible.sync="dialogVisible1"
        width="50%"
        :before-close="detailsX"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <!-- <el-form-item label="id" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.id"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="创建人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.createId"></el-input>
          </el-form-item>
          <el-form-item label="修改人的ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.updateId"></el-input>
          </el-form-item> -->
          <el-form-item label="创建人名称" prop="name">
            <el-input
              v-model="ruleForm1.createName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改人名称" prop="name">
            <el-input
              v-model="ruleForm1.updateName"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建日期" prop="createDate">
            <el-input
              v-model="ruleForm1.createDate"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="修改日期" prop="name">
            <el-input
              v-model="ruleForm1.updateDate"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构名称" prop="name">
            <el-input v-model="ruleForm1.orgName" :disabled="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="父级ID" prop="name" v-if="isShow">
            <el-input v-model="ruleForm1.parentId"></el-input>
          </el-form-item> -->
          <el-form-item label="联系人" prop="name">
            <el-input v-model="ruleForm1.linkName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="删除标识" prop="name">
            <el-input v-model="ruleForm1.delFlag" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="name">
            <el-input v-model="ruleForm1.sex" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="name">
            <el-input v-model="ruleForm1.phone" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="社会统一信用代码" prop="name">
            <el-input
              v-model="ruleForm1.creditCode"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构英文名称" prop="orgEname">
            <el-input
              v-model="ruleForm.orgEname"
              style="width: 230px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构logo图标" prop="logoUrl">
            <img :src="this.ruleForm.logoUrl" style="width: 200px" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsX">关闭</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 二次弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleTwo" width="30%">
      <span>确认删除此信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTwo = false">取 消</el-button>
        <el-button type="primary" @click="deleteTwo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible"
      width="30%"
      :before-close="handleCloseImage"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange" style="margin:2% 4%"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange1" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import rolemanage from "../system_maintain/rolemanage";
// import addaccount from "../system_maintain/addaccount";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleTwo: false,
      dialogVisible2: false,
      isShow: true,
      pagesize: 8,
      pagercount: 5,
      pageCount: 1,
      title: "",
      ruleForm: {
        orgName: "",
        linkName: "",
        sex: "",
        phone: "",
        creditCode: "",
        orgEname: "",
        logoUrl: "",
      },
      ruleForm1: {
        delFlag: "",
        createName: "",
        updateName: "",
        createDate: "",
        updateDate: "",
        orgName: "",
        linkName: "",
        sex: "",
        phone: "",
        creditCode: "",
      },
      currentPage1: 1,
      pageSize: 10,
      totalCount: 1000,
      //pageCount: "", //网络请求的页码
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      twoID: "",
      //   table
      tableData: [],
      options2: [],
      flagsex: false,
      XB: "",
      rules: {
        ID: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      imageVisible: false,
      imageVisible1: false,
      //上传图片
      token: "", //上传头像的token
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      token1: "", //上传头像的token
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      imageUrl: "", //图片路径
      id: "",
      photoUrl: "",
      flag: false,
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist();
    this.sexXia();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //增加点击上传图片的按钮
    pushImage() {
      this.imageVisible = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "org" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //增加确定上传图片
    uploadImage() {
      this.imageVisible = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("backIma"));
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
        return;
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      console.log(
        "用户：",
        file,
        this.prefix,
        this.id,
        shijiancuo,
        ming,
        token
      );
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("orgPhotoAdd", JSON.stringify(res.key));
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          document.getElementById("addProImage").src = bIm;
          // document.getElementById("gengaiImage").src=bIm;
          // alert('上传成功');
          //存本地
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传头像格式限制
    fileChange(e) {
      console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // console.log('ref:',this.$refs.file.files);

      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backIma", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }

      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = file.size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    changeOrgList() {
      console.log("this.flag", this.flag);
      if (this.flagsex == false) {
        if (this.ruleForm.sex == "女") {
          this.ruleForm.sex = "0";
        } else {
          this.ruleForm.sex = "1";
        }
      } else {
        this.ruleForm.sex = this.XB;
      }
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("orgPhoto"));
      if (this.flag == true) {
        this.ruleForm.logoUrl = "http://cdn.careld.cn/" + proPh;
      } else {
        this.ruleForm.logoUrl = this.imageUrl;
      }
      let { id, orgName, linkName, sex, phone, creditCode, orgEname, logoUrl } =
        this.ruleForm;
      api
        .getchangeOrg({
          id,
          orgName,
          linkName,
          sex,
          phone,
          creditCode,
          orgEname,
          logoUrl,
        })
        .then((res) => {
          console.log("编辑机构：", res.data);
          if (res.data.code === 200) {
            //添加成功
            this.$message({
              message: "恭喜你，编辑机构成功",
              type: "success",
            });
            this.dialogVisible2 = false;
            //清空表单数据
            this.clearOrgForm();
            this.flag = false;
            //刷新页面数据
            this.menulist({ pageCount: this.currentPage1 });
          }
        });
    },
    //更改点击上传图片的按钮
    pushImage1() {
      this.imageVisible1 = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "org" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token1 = res.data.result.token;
        this.accessKey1 = res.data.result.accessKey;
        this.bucket1 = res.data.result.bucket;
        this.secretKey1 = res.data.result.secretKey;
        this.prefix1 = res.data.result.prefix;
      });
    },
    //更改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flag = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("backIma1"));
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
        return;
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token1 = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token1,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("orgPhoto", JSON.stringify(res.key));
          console.log("this:", this);
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          // document.getElementById("addProImage").src=bIm;
          document.getElementById("gengaiImage").src = bIm;
          // alert('上传成功');
          //存本地
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //更改上传头像格式限制
    fileChange1(e) {
      console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      // console.log('ref:',this.$refs.file.files);

      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backIma1", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }

      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = file.size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    handleCloseImage() {
      this.imageVisible = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    clearorgForm2() {
      this.dialogVisible2 = false;
    },
    //性别的下拉框
    sexXia() {
      api.smallLei({ dictCode: "sex" }).then((res) => {
        //console.log('性别：',res.data);
        if (res.data.code === 200) {
          this.options2 = res.data.result;
        }
      });
    },
    //性别
    getSexValue(value) {
      console.log("性别的选中：", value);
      this.flagsex = true;
      this.XB = value;
      //console.log('别：',value);
      // if (value === "") {
      //   this.ruleForm.sex = "";
      // } else {
      //   this.ruleForm.sex = value;
      // }
    },
    menulist(page) {
      api.getOrgList({ pageCount: page }).then((res) => {
        console.log("机构列表：", res.data);
        if (res.data.code === 200) {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //添加机构
    add() {
      this.dialogVisible = true;
      // this.title = "添加机构";
      this.ruleForm = {};
      this.imageUrl = "";
    },
    //取消清空数据
    clearorgForm() {
      this.dialogVisible = false;
      //清空表单数据
      this.clearOrgForm();
    },
    //添加机构
    addOrgList() {
      this.dialogVisible = false;
      let proPh = JSON.parse(localStorage.getItem("orgPhotoAdd"));
      this.ruleForm.logoUrl = proPh;
      if (!/^\+?[A-Za-z]*$/.test(this.ruleForm.orgEname)) {
        this.$message.error("机构英文名称只能输入英文");
        return false;
      }
      let { orgName, linkName, sex, phone, creditCode, orgEname, logoUrl } =
        this.ruleForm;

      api
        .getAddOrgList({
          orgName,
          linkName,
          sex,
          phone,
          creditCode,
          orgEname,
          logoUrl,
        })
        .then((res) => {
          console.log("添加机构：", res.data);
          if (res.data.code == 200) {
            //添加成功
            this.$message({
              message: "恭喜你，添加机构成功",
              type: "success",
            });
            //清空表单数据
            this.clearOrgForm();
            //刷新页面数据
            this.menulist({ pageCount: this.currentPage1 });
          }
        });
    },
    //编辑机构
    changeOrg(index, row) {
      console.log("bianji", index, row);
      // this.title = "编辑机构";
      this.isShow = false;
      this.ruleForm = { ...row };
      this.imageUrl = row.logoUrl;
      //console.log('修改之后：',this.ruleForm);
      this.dialogVisible2 = true;
    },
    //删除机构
    deleteOrg(index, row) {
      console.log(index, row);
      this.dialogVisibleTwo = true;
      this.twoID = row.id;
    },
    deleteTwo() {
      this.dialogVisibleTwo = false;
      api.deleteOrgList({ id: this.twoID }).then((res) => {
        console.log("删除机构:", res.data);
        if (res.data.code == 200) {
          //删除成功
          this.$message({
            message: "恭喜你，删除机构成功",
            type: "success",
          });
          this.clearOrgForm();
          //刷新页面数据
          this.menulist({ pageCount: this.currentPage1 });
        }
      });
    },
    //查询机构详情
    details(index, row) {
      //console.log('index',index,'row',row);
      this.dialogVisible1 = true;
      api.searchOrgList({ id: row.id }).then((res) => {
        console.log("机构详情:", res.data);
        if (res.data.code == 200) {
          let collectTime = res.data.result.createDate;
          this.timeData = new Date(parseInt(collectTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          console.log("时间：", this.timeData);
          let collTime = res.data.result.updateDate;
          this.timeDa = new Date(parseInt(collTime))
            .toLocaleString()
            .replace(/:\d{1,2}$/, " ");
          res.data.result.createDate = this.timeData;
          res.data.result.updateDate = this.timeDa;
          this.ruleForm1 = res.data.result;
        }
      });
    },
    //清空表单
    clearOrgForm() {
      this.dialogVisible = false; //隐藏弹框
      this.ruleForm = {
        orgName: "",
        linkName: "",
        sex: "",
        phone: "",
        creditCode: "",
      };
      //单独清除下拉框
      this.value = "";
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
      //清空表单数据
      this.clearOrgForm();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.menulist(val);
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 3px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;
    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
        margin-top: 2px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 19px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;
        margin-left: 80px;
        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          .el-pager,
          .el-pager li {
            margin-top: 2px;
            color: #afafaf;
            min-width: 50px;
            font-size: 17px;
            font-weight: normal;
          }
          .el-pager li.active {
            color: #d78673;
          }
          .el-pager li:hover {
            color: #d78673;
          }
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  //  .el-input__inner {
  //   width: 97%;
  // }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  // // 弹框
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px;
    line-height: 33px;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 60%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>